<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="font-weight-black">
      <span>
        Ocorrencias vinculadas
        <v-progress-circular
          v-if="carregandoRemoverOcorrencia || carregandoAdicionarOcorrencia || carregandoBuscarOcorrencias"
          indeterminate
          :size="15"
          :width="2"
          color="primary"
          class="ml-2"
        />
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row no-gutters>
        <v-col class="no-gutters">
          <i
            v-if="Object.keys(ocorrencias).length <= 0 && !carregandoBuscarOcorrencias && !carregandoAdicionarOcorrencia"
            class="grey--text"
          >
            <small>Nenhuma ocorrência vinculada</small>
          </i>
          <v-chip
            v-for="(ocorrencia, key) in ocorrencias"
            v-else
            :key="key"
            class="my-1 mr-1"
            close
            label
            close-icon="mdi-delete"
            outlined
            @click:close="removerOcorrencia(ocorrencia)"
          >
            {{ ocorrencia.descricao }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!seeOnly">
        <v-col class="no-gutters">
          <v-btn
            outlined
            small
            color="success"
            :disabled="!ocorrenciaId || carregandoBuscarOcorrencias || carregandoAdicionarOcorrencia"
            @click="mostrarDialogPesquisarOcorrencia = true"
          >
            <v-icon
              left
              v-text="'mdi-plus'"
            />
            Adicionar
          </v-btn>

          <dialog-pesquisar-ocorrencia
            :mostrar="mostrarDialogPesquisarOcorrencia"
            @fechar="mostrarDialogPesquisarOcorrencia = false"
            @change="adicionarOcorrencia"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import ocorrenciasApi from '@/api/ocorrencias'

  export default {
    components: {
      DialogPesquisarOcorrencia: () => import('@/components/dialog/ocorrencias/DialogPesquisarOcorrencia'),
    },

    props: {
      ocorrenciaId: {
        type: Number,
        default: 0,
      },
      seeOnly: {
      type: Boolean,
      default:false,
    },
    },

    data () {
      return {
        mostrarDialogPesquisarOcorrencia: false,
        carregandoBuscarOcorrencias: false,
        carregandoAdicionarOcorrencia: false,
        carregandoRemoverOcorrencia: false,
        ocorrencias: [],
      }
    },

    mounted () {
      this.buscarOcorrencias()
    },

    methods: {
      async buscarOcorrencias () {
        if (!this.ocorrenciaId) return

        try {
          this.carregandoBuscarOcorrencias = true
          const resposta = await ocorrenciasApi.buscarOcorrenciasRelacionadas(this.ocorrenciaId)
          this.ocorrencias = resposta.data
        } catch (e) {
          console.error(e)
        } finally {
          this.carregandoBuscarOcorrencias = false
        }
      },

      async adicionarOcorrencia (ocorrencia) {
        try {
          this.mostrarDialogPesquisarOcorrencia = false
          this.carregandoAdicionarOcorrencia = true

          const resposta = await ocorrenciasApi.adicionarOcorrenciaRelacionada(this.ocorrenciaId, {
            ocorrencia_id: ocorrencia.id,
          })

          this.ocorrencias = resposta.data
        } catch (e) {
          console.error(e)
        } finally {
          this.carregandoAdicionarOcorrencia = false
        }
      },

      async removerOcorrencia (ocorrencia) {
        try {
          this.carregandoRemoverOcorrencia = true
          const resposta = await ocorrenciasApi.removerOcorrenciaRelacionada(this.ocorrenciaId, {
            ocorrencia_id: ocorrencia.id,
          })
          this.ocorrencias = resposta.data
        } catch (e) {
          console.error(e)
        } finally {
          this.carregandoRemoverOcorrencia = false
        }
      },
    },
  }
</script>
